var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",staticClass:"elevation-1 general-table",attrs:{"headers":_vm.sortedHeaders,"items":_vm.mappedRows,"items-per-page":_vm.mappedRows.length,"custom-sort":_vm.customSort,"sort-by":_vm.sortBy,"sort-desc":[true, false],"options":_vm.options,"fixed-header":"","loading":_vm.loading,"hide-default-footer":"","loading-text":_vm.$t('bankingProfile.table.loadingText')},scopedSlots:_vm._u([{key:"body.append",fn:function(){return _vm._l((_vm.rows),function(item,index){return _c('tr',{key:index},[(
          item.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
          item.ausschlussKriterien_0_bemerkung
        )?_c('td',{attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item['anbieter_anbietername'])+" ")]):_vm._e(),(
          item.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
          item.ausschlussKriterien_0_bemerkung
        )?_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length - 2}},[(Number(_vm.reportType) === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('liveData.generalTable.noOfferTagesgeld')))]):_c('span',[_vm._v(_vm._s(_vm.$t('liveData.generalTable.noOfferFestgeld')))])]):_vm._e(),(
          item.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
          item.ausschlussKriterien_0_bemerkung &&
          !_vm.validate(item)
        )?_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"1"}},[_c('div',[(
              (item['einlagensicherung_0_flaggeUrl'] &&
                item['einlagensicherung_0_flaggeUrl'] ===
                  item['einlagensicherung_1_flaggeUrl']) ||
              (item['einlagensicherung_1_flaggeUrl'] &&
                item['einlagensicherung_0_flaggeUrl'] !==
                  item['einlagensicherung_1_flaggeUrl'])
            )?_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item['einlagensicherung_0_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_0_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e(),(item['einlagensicherung_1_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_1_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e()])]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Erweiterte Einlagensicherung")])]):_c('div',[(item['einlagensicherung_0_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_0_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e(),(item['einlagensicherung_1_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_1_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e()]),(
              item['einlagensicherung_0_flaggeUrl'] &&
              item['einlagensicherung_0_flaggeUrl'] ===
                item['einlagensicherung_1_flaggeUrl']
            )?_c('div',{staticClass:"country_description"},[_vm._v(" ("+_vm._s(item['einlagensicherung_1_landname'])+" +) ")]):(
              item['einlagensicherung_1_flaggeUrl'] &&
              item['einlagensicherung_0_flaggeUrl'] !==
                item['einlagensicherung_1_flaggeUrl']
            )?_c('div',{staticClass:"country_description"},[_vm._v(" ("+_vm._s(item['einlagensicherung_0_landname'])+" u. D +) ")]):(item['einlagensicherung_0_flaggeUrl'])?_c('div',{staticClass:"country_description"},[_vm._v(" ("+_vm._s(item['einlagensicherung_0_landname'])+") ")]):_vm._e()],1)]):_vm._e(),(
          item.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
          item.ausschlussKriterien_0_bemerkung &&
          _vm.validate(item)
        )?_c('td',{attrs:{"colspan":"1"}}):_vm._e()])})},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.baseBlackColor,"indeterminate":""}})]},proxy:true},{key:"no-data",fn:function(){return [(_vm.validateNodata)?_c('span',{staticClass:"v-data-table__empty-wrapper"},[_vm._v("Keine Daten vorhanden")]):_c('span',[_vm._v("Keine Daten vorhanden")])]},proxy:true},{key:"item.anbieter",fn:function(ref){
        var item = ref.item;
return [(item['anbieter_anbieterLogoUrl'])?_c('div',{staticClass:"pl-2 pr-2"},[_c('img',{attrs:{"width":"120","height":"40","src":(_vm.url + "/api/proxy?url=" + (encodeURIComponent(
          item['anbieter_anbieterLogoUrl']
        ))),"alt":item['anbieter_anbietername']}})]):_c('div',{staticClass:"pl-2 pr-2"},[_c('span',[_vm._v(_vm._s(item['anbieter_anbietername']))])])]}},{key:"item.produkt",fn:function(ref){
        var item = ref.item;
return [(item['produkt_produktname'])?_c('span',[_vm._v(_vm._s(_vm.productValue(item['produkt_produktname'])))]):_vm._e()]}},{key:"item.produkt_zinssatz",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.hasOwnProperty('produkt_zinssatz'))?_c('span',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v(_vm._s(_vm.numberFormat(item.produkt_zinssatz))+"%")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCompare),expression:"isCompare"}]},[_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip"},'div',attrs,false),on),[(_vm.validateDifference(item.produkt_zinssatz_difference))?_c('div',{staticStyle:{"font-size":"smaller"}},[_c('v-icon',{staticClass:"mdi mdi-triangle-outline",attrs:{"color":"grey","size":"small"}}),_vm._v(" "+_vm._s(_vm.numberFormat(item.produkt_zinssatz_difference))+"% "),_c('span',[(item.produkt_zinssatz_trend === 'up')?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e(),(item.produkt_zinssatz_trend === 'down')?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e()],1)],1):_vm._e()])]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Veränderungen in Vergleich zum "+_vm._s(_vm.dateMit))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCompare),expression:"!isCompare"}]},[_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(
                item.produkt_zinssatzVeraenderung !== undefined &&
                item.produkt_zinssatzVeraenderung !== 0 &&
                item.produkt_zinssatzVeraenderung !== '0.00'
              )?_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mdi mdi-triangle-outline",attrs:{"color":"grey","size":"small"}}),_vm._v(" "+_vm._s(_vm.numberFormat(item.produkt_zinssatzVeraenderung))+"% "),_c('span',[(item.produkt_zinssatzVeraenderung > 0)?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e(),(item.produkt_zinssatzVeraenderung < 0)?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e()],1)],1):_vm._e()]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Trend der letzten zwei Wochen")])])],1)])]}},{key:"item.produkt_aktionszinssatz",fn:function(ref){
              var item = ref.item;
return [_c('span',[(item.produkt_aktionszinssatz)?_c('span',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v(_vm._s(_vm.numberFormat(item.produkt_aktionszinssatz))+"%")]):_c('span',[_vm._v("-")])]),(
        item.produkt_aktionszinssatzVeraenderung &&
        item.produkt_aktionszinssatzVeraenderung !== 0 &&
        item.produkt_aktionszinssatzVeraenderung !== '0.00'
      )?_c('div',[_c('v-icon',{staticClass:"mdi mdi-triangle-outline",attrs:{"color":"grey","size":"small"}}),_c('span',[_vm._v(_vm._s(_vm.numberFormat(item.produkt_aktionszinssatzVeraenderung))+"%")]),_c('span',[(item.produkt_aktionszinssatzVeraenderung > 0)?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e(),(item.produkt_aktionszinssatzVeraenderung < 0)?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e()],1)],1):_vm._e()]}},_vm._l((_vm.monthFields),function(field,index){return {key:_vm.getSlotName(field),fn:function(ref){
      var item = ref.item;
return [_c('div',{key:index},[(item.hasOwnProperty(field))?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.dataItem(item[field])))]):_vm._e(),(_vm.isCompare)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip"},'div',attrs,false),on),[(_vm.monthFields.indexOf((field + "_difference")) > -1)?_c('div',{staticClass:"icons-container"},[(_vm.validateDifference(item[(field + "_difference")]))?_c('div',{staticStyle:{"font-size":"smaller"}},[_c('v-icon',{staticClass:"mdi mdi-triangle-outline",attrs:{"color":"grey","size":"small"}}),_vm._v(" "+_vm._s(_vm.numberFormat(item[(field + "_difference")]))+"% "),_c('span',[(item[(field + "_trend")] === 'up')?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e(),(item[(field + "_trend")] === 'down')?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e()],1)],1):_vm._e()]):_vm._e()])]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Veränderungen in Vergleich zum "+_vm._s(_vm.dateMit))])])],1):_c('div',[(_vm.monthFields.indexOf((field + "Veraenderung")) > -1)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip"},'div',attrs,false),on),[_c('div',{staticClass:"icons-container"},[(
                      item[(field + "Veraenderung")] &&
                      item[(field + "Veraenderung")] !== 0 &&
                      item[(field + "Veraenderung")] !== '0.00' &&
                      item[(field + "Veraenderung")] !== '-'
                    )?_c('div',{staticStyle:{"font-size":"smaller"}},[_c('v-icon',{staticClass:"mdi mdi-triangle-outline",attrs:{"color":"grey","size":"small"}}),_vm._v(" "+_vm._s(_vm.numberFormat(item[(field + "Veraenderung")]))+"% ")],1):_vm._e(),_c('span',[(item[(field + "Veraenderung")] > 0)?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e(),(item[(field + "Veraenderung")] < 0)?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e()],1)])])]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Trend der letzten zwei Wochen")])])],1):_vm._e()])])]}}}),{key:"item.produkt_zinsgutschrift",fn:function(ref){
                    var item = ref.item;
return [(item.hasOwnProperty('produkt_zinsgutschrift'))?_c('span',[_vm._v(_vm._s(_vm.dataInterestCredit(item.produkt_zinsgutschrift)))]):_vm._e()]}},{key:"item.produkt_zinsberechnung",fn:function(ref){
                    var item = ref.item;
return [(item.hasOwnProperty('produkt_zinsberechnung'))?_c('span',[_vm._v(_vm._s(_vm.dataInterestCalculation(item.produkt_zinsberechnung)))]):_vm._e()]}},{key:"item.produkt_datumZinsaenderung",fn:function(ref){
                    var item = ref.item;
return [(item.produkt_datumZinsaenderung)?_c('span',[_vm._v(_vm._s(_vm.$date(item.produkt_datumZinsaenderung).format('DD.MM.YYYY')))]):_vm._e()]}},{key:"item.produkt_mindestanlage",fn:function(ref){
                    var item = ref.item;
return [(item.produkt_mindestanlage)?_c('span',[(item.produkt_mindestanlage.toString().length > 3)?_c('span',[_vm._v(" "+_vm._s(item.produkt_mindestanlage.toLocaleString('de-DE'))+" € ")]):_c('span',[_vm._v(" "+_vm._s(item.produkt_mindestanlage)+" € ")])]):_vm._e()]}},{key:"item.produkt_maximalanlage",fn:function(ref){
                    var item = ref.item;
return [(item.produkt_maximalanlage)?_c('span',[(item.produkt_maximalanlage.toString().length > 3)?_c('span',[_vm._v(" "+_vm._s(item.produkt_maximalanlage.toLocaleString('de-DE'))+" € ")]):_c('span',[_vm._v(" "+_vm._s(item.produkt_maximalanlage)+" €")])]):_c('span',[(item.produkt_produktname)?_c('span',[_vm._v("unbegrenzt")]):_c('span')])]}},{key:"item.einlagensicherung",fn:function(ref){
                    var item = ref.item;
return [(_vm.validate(item))?_c('div',[_c('span')]):_c('div',[(
          (item['einlagensicherung_0_flaggeUrl'] &&
            item['einlagensicherung_0_flaggeUrl'] ===
              item['einlagensicherung_1_flaggeUrl']) ||
          (item['einlagensicherung_1_flaggeUrl'] &&
            item['einlagensicherung_0_flaggeUrl'] !==
              item['einlagensicherung_1_flaggeUrl'])
        )?_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item['einlagensicherung_0_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_0_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e(),(item['einlagensicherung_1_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_1_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e()])]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Erweiterte Einlagensicherung")])]):_c('div',[(item['einlagensicherung_0_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_0_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e(),(item['einlagensicherung_1_flaggeUrl'])?_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=" + (item['einlagensicherung_1_flaggeUrl'])),"alt":"Einlagensicherung"},on:{"error":function($event){return _vm.handleError($event, item)}}}):_vm._e()]),(
          item['einlagensicherung_0_flaggeUrl'] &&
          item['einlagensicherung_0_flaggeUrl'] ===
            item['einlagensicherung_1_flaggeUrl']
        )?_c('div',{staticClass:"country_description"},[_vm._v(" ("+_vm._s(item['einlagensicherung_1_landname'])+" +) ")]):(
          item['einlagensicherung_1_flaggeUrl'] &&
          item['einlagensicherung_0_flaggeUrl'] !==
            item['einlagensicherung_1_flaggeUrl']
        )?_c('div',{staticClass:"country_description"},[_vm._v(" ("+_vm._s(item['einlagensicherung_0_landname'])+" u. D +) ")]):(item['einlagensicherung_0_flaggeUrl'])?_c('div',{staticClass:"country_description"},[_vm._v(" ("+_vm._s(item['einlagensicherung_0_landname'])+") ")]):_vm._e()],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }