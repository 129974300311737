var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",staticClass:"elevation-1 fmh-table",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.mappedHeaders,"items":_vm.mappedRows,"items-per-page":_vm.mappedRows.length},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Keine Daten vorhanden")])]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_vm._l(([_vm.processTableHeaders(props.headers)]),function(headers,i){return [_c('tr',{key:Math.random() + i},_vm._l((headers.parents),function(header,index){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(header.description),expression:"header.description"}],key:index.toString(),class:{
              product: !header.isDynamic,
              'parent-header': header.isDynamic,
            },attrs:{"rowspan":1,"colspan":header.descriptionColSpan}},[_vm._v(" "+_vm._s(header.description)+" ")])}),0),_c('tr',{key:Math.random() + i},_vm._l((headers.parents),function(header){
            var _obj;
return _c('th',{key:header.value,staticClass:"border",attrs:{"rowspan":header.rowspan,"colspan":header.colspan}},[_c('div',{staticClass:"boxTh",class:( _obj = {}, _obj[header.className] = header.className, _obj['children-header-not-product'] =  header.isDynamic, _obj )},[_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])])])}),0),(headers.children)?_c('tr',{key:Math.random() + i + 1},_vm._l((headers.children),function(header,index){return _c('th',{key:Math.random() + header.value,staticClass:"border-child",class:_vm.setClasses(header),on:{"click":function () { return index !== 0 && _vm.customSort(header, index); }}},[_c('div',{staticClass:"boxTh"},[_c('span',[_vm._v(" "+_vm._s(header.text)+" "),_c('br'),(
                    _vm.isCompare &&
                    header.value.includes('produkt_effektivzins_old')
                  )?_c('span',{style:({ color: _vm.selectedColor })},[_vm._v(" "+_vm._s(_vm.dateMit)+" ")]):_vm._e(),(
                    _vm.isCompare &&
                    header.value.includes('produkt_effektivzins') &&
                    !header.value.includes('produkt_effektivzins_old')
                  )?_c('span',{style:({ color: _vm.selectedColor })},[_vm._v(" "+_vm._s(_vm.dateVom)+" ")]):_vm._e()]),(index !== 0)?_c('v-icon',{staticClass:"myArrowStyle",class:_vm.iconRotateClass(index)},[_vm._v(_vm._s('mdi-arrow-up')+" ")]):_vm._e()],1)])}),0):_vm._e()]})],2)]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',_vm._l((item),function(ref,index){
                  var _obj, _obj$1, _obj$2;

                  var value = ref.value;
                  var header = ref.header;
                  var className = ref.className;
                  var compare = ref.compare;
                  var isDynamic = ref.isDynamic;return _c('td',{key:index + Math.random(),staticClass:"text-center",class:( _obj = {}, _obj[className] = className, _obj['font-basic-reference'] =  isDynamic, _obj )},[(header === 'a_anbieter')?_c('p',[_c('span',{staticClass:"opacity"},[_vm._v(_vm._s(_vm.replaceAnbieterData(value).bankName))]),(_vm.replaceAnbieterData(value).url !== '')?_c('img',{attrs:{"width":"120","height":"40","src":(_vm.url + "/api/proxy?url=" + (encodeURIComponent(
              _vm.replaceAnbieterData(value).url
            ))),"alt":_vm.replaceAnbieterData(value).bankName}}):_c('span',[_vm._v(_vm._s(_vm.replaceAnbieterData(value).bankName))])]):_c('div',[(!_vm.isCompare)?_c('div',[(
                header.includes(
                  'neben_zinsrabattaufschlag_maximaldarlehen'
                ) && _vm.banksData(header, value) === '0'
              )?_c('span',[_vm._v("unbegrenzt")]):_c('span',[_c('span',{class:( _obj$1 = {}, _obj$1['font-sollzins'] = _vm.addFontSollzins(header, value), _obj$1 )},[_vm._v(_vm._s(_vm.banksData(header, value)))])])]):_c('div',[_c('div',[(
                  header.includes(
                    'neben_zinsrabattaufschlag_maximaldarlehen'
                  ) && _vm.banksData(header, value) === '0'
                )?_c('span',[_vm._v("unbegrenzt")]):_c('span',[_c('span',{class:( _obj$2 = {}, _obj$2['font-sollzins'] = _vm.addFontSollzins(header, value), _obj$2 )},[_vm._v(_vm._s(_vm.banksData(header, value)))])])]),(_vm.validate(header, compare))?_c('div',[(Object.keys(compare || {}).length)?_c('div',{staticStyle:{"font-size":"smaller"}},[_c('v-tooltip',{attrs:{"top":"","color":_vm.baseColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mdi mdi-triangle-outline",attrs:{"color":"grey","size":"small"}}),_vm._v(" "+_vm._s(_vm.modifyDifference( header, compare[(header + "_difference")] ))+" "),((header + "_difference").includes('produkt'))?_c('span',[(compare[(header + "_trend")] === 'up')?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e(),(compare[(header + "_trend")] === 'down')?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e()],1):_c('span',[(compare[(header + "_trend")] === 'up')?_c('v-icon',{staticClass:"mdi mdi-arrow-top-right-thick",staticStyle:{"color":"green"},attrs:{"size":"20"}}):_vm._e(),(compare[(header + "_trend")] === 'down')?_c('v-icon',{staticClass:"mdi mdi-arrow-bottom-right-thick",staticStyle:{"color":"red"},attrs:{"size":"20"}}):_vm._e()],1)],1)]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v("Veränderungen in Vergleich zum "+_vm._s(_vm.dateMit))])])],1):_vm._e()]):_vm._e()])])])}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }