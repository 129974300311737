




























































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import Loader from '@/components/fmh_components/common/Loader/Loader.vue';

import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  INTEREST_CREDIT_FESTGELD,
  BACKEND_URL,
} from '@/utils/constants';
import {
  modifyNumber,
  mappedInterestCalculation,
  getSortedHeaders,
} from '@/utils/helpers';

import { mappedInterestCredit } from '@/utils/helpers/helpers';

@Component({ components: { Loader } })
export default class GeneralTable extends Vue {
  @Prop({ default: () => [] }) headers: Array<object> | undefined;
  @Prop({ default: () => [] }) rows: Array<object> | undefined;
  @Prop({ default: () => [] }) reportType: Number | undefined;
  @Prop() isCompare!: Boolean;
  @Prop() dateMit: string | undefined;
  @Prop() config: Object | undefined;
  @Prop({ default: false }) loading?: Boolean;

  url: String = BACKEND_URL;
  baseColor: String = BASE_COLOR;
  baseBlackColor = BASE_BLACK_COLOR;
  errors = {};
  options = {
    mustSort: true,
  };

  get mappedRows() {
    if (
      (this.rows || []).some(
        (i: any) =>
          i.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
          i.ausschlussKriterien_0_bemerkung
      )
    ) {
      return (this.rows || []).filter(
        (i) => !i.hasOwnProperty('ausschlussKriterien_0_bemerkung')
      );
    }
    return this.rows;
  }
  get validateNodata() {
    return (
      (this.rows || []).some(
        (i: any) =>
          i.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
          i.ausschlussKriterien_0_bemerkung &&
          [1, 2].includes(Number(this.reportType))
      ) && !this.mappedRows?.length
    );
  }
  get sortedHeaders() {
    return getSortedHeaders(this.headers, Number(this.reportType));
  }
  get sortBy() {
    if (Number(this.reportType) === 1) {
      return 'produkt_zinssatz';
    } else {
      const subArr: string[] = [];
      (this.rows || []).forEach((row) => {
        Object.keys(row).forEach((k: string) => {
          if (!subArr.includes(k)) {
            subArr.push(k);
          }
        });
      });
      const keys = subArr.filter((key) => /^\d.*M\_/.test(key));
      const numbers = [
        ...new Set(keys.map((key) => Number((key || '').replace(/\D/g, '')))),
      ];
      const max = Math.max(...numbers);
      const search = keys.find((item) => item.includes(`${max}M_zinssatz`));
      if (search) {
        return search;
      }
    }
  }
  get monthFields() {
    const array: string[] = [];
    if (this.rows && this.rows.length) {
      const subArr: string[] = [];
      this.rows.forEach((row) => {
        Object.keys(row).forEach((k: string) => {
          if (!subArr.includes(k)) {
            subArr.push(k);
          }
        });
      });
      const keys = subArr.filter((key) => /^\d.*M\_/.test(key));
      array.push(...keys);
    }
    return array;
  }
  sortByDate(items, sortBy, sortDesc) {
    return items.sort((a, b) => {
      const partsA = a[sortBy].split('.');
      const partsB = b[sortBy].split('.');
      let first = new Date(a[sortBy]).getTime() || 0;
      let second = new Date(b[sortBy]).getTime() || 0;
      //the valid date format for new Date is YYYY-MM-DD
      if (partsA.length === 3) {
        first = new Date(
          `${partsA[2]}-${partsA[1]}-${partsA[0]}T00:00:00`
        ).getTime();
      }
      if (partsB.length === 3) {
        second = new Date(
          `${partsB[2]}-${partsB[1]}-${partsB[0]}T00:00:00`
        ).getTime();
      }

      if (sortDesc.includes(true)) {
        if (second > first) {
          return 1;
        }
        if (second < first) {
          return -1;
        }
        return 0;
      }
      if (sortDesc.includes(false)) {
        if (first > second) {
          return 1;
        }
        if (first < second) {
          return -1;
        }
        return 0;
      }
    });
  }
  sortStrings(items, sortBy, sortDesc) {
    return items.sort((a, b) => {
      const first = (a[sortBy] || '').trim().toLowerCase();
      const second = (b[sortBy] || '').trim().toLowerCase();
      if (sortDesc.includes(true)) {
        return first > second ? -1 : 1;
      }
      if (sortDesc.includes(false)) {
        return first > second ? 1 : -1;
      }
    });
  }
  sortInterestRate(items, sortBy, sortDesc) {
    const months: object[] = [];
    const dates: object[] = [];
    const rest: object[] = [];
    items.forEach((item) => {
      if (typeof item.produkt_zinsgarantie === 'string') {
        const parts = item.produkt_zinsgarantie.split('.');
        if (parts.length !== 3) {
          months.push(item);
        }
        if (parts.length === 3) {
          dates.push(item);
        }
      }
      if (!item.produkt_zinsgarantie) {
        rest.push({ ...item, produkt_zinsgarantie: '' });
      }
    });
    const sortedMonth = this.sortStrings(
      months,
      'produkt_zinsgarantie',
      sortDesc
    );
    const sortedDates = this.sortByDate(
      dates,
      'produkt_zinsgarantie',
      sortDesc
    );
    if (sortDesc.includes(true)) {
      return sortedMonth.concat(sortedDates).concat(rest);
    }
    if (sortDesc.includes(false)) {
      return rest.concat(sortedDates).concat(sortedMonth);
    }
  }
  sortCredit(items, sortBy, sortDesc) {
    const daily: object[] = [];
    const beginningMonth: object[] = [];
    const endOfMonth: object[] = [];
    const endOfQuarter: object[] = [];
    const endOfHalfYear: object[] = [];
    const yearEnd: object[] = [];
    const rest: object[] = [];
    items.forEach((item) => {
      if (!isNaN(+item[sortBy])) {
        const val = item[sortBy];
        //Monatsende
        if (+val === 0) {
          endOfMonth.push(item);
        }
        //Quartalsende
        if (+val === 1) {
          endOfQuarter.push(item);
        }
        //Jahresende
        if (val === 2) {
          yearEnd.push(item);
        }
        //Täglich
        if (+val === 3) {
          daily.push(item);
        }
        //Halbjahresende
        if (+val === 4) {
          endOfHalfYear.push(item);
        }
        //Monatsanfang
        if (+val === 5) {
          beginningMonth.push(item);
        }
      }
      if (isNaN(+item[sortBy])) {
        rest.push(item);
      }
    });
    if (sortDesc.includes(true)) {
      return daily
        .concat(beginningMonth)
        .concat(endOfMonth)
        .concat(endOfQuarter)
        .concat(endOfHalfYear)
        .concat(yearEnd)
        .concat(rest);
    }
    if (sortDesc.includes(false)) {
      return rest
        .concat(yearEnd)
        .concat(endOfHalfYear)
        .concat(endOfQuarter)
        .concat(endOfMonth)
        .concat(beginningMonth)
        .concat(daily);
    }
  }
  compare(a, b) {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  }
  validate(item) {
    if (this.errors[String(item.anbieter_id)]?.length) {
      return true;
    }
    return (
      !item.einlagensicherung_0_flaggeUrl && !item.einlagensicherung_1_flaggeUrl
    );
  }
  handleError(e, item) {
    this.errors = {
      ...this.errors,
      [item.anbieter_id]: [].concat(e.target.src),
    };
  }
  customSort(items, sortBy, sortDesc) {
    if (sortBy.includes('produkt_zinsgutschrift')) {
      return this.sortCredit(items, 'produkt_zinsgutschrift', sortDesc);
    }
    if (sortBy.includes('produkt_zinsgarantie')) {
      return this.sortInterestRate(items, sortBy, sortDesc);
    }
    if (sortBy.includes('anbieter')) {
      return this.sortStrings(items, 'anbieter_anbietername', sortDesc);
    }
    if (sortBy.includes('produkt')) {
      return this.sortStrings(items, 'produkt_produktname', sortDesc);
    }
    if (sortBy.includes('produkt_datumZinsaenderung')) {
      return this.sortByDate(items, 'produkt_datumZinsaenderung', sortDesc);
    }
    if (
      sortBy[0] !== 'anbieter' &&
      sortBy[0] !== 'produkt_datumZinsaenderung' &&
      sortBy[0] !== 'produkt' &&
      sortDesc.includes(true)
    ) {
      return items.sort((a, b) => {
        const first = +a[sortBy[0]] || 0;
        const second = +b[sortBy[0]] || 0;
        return second - first;
      });
    }
    if (
      sortBy[0] !== 'anbieter' &&
      sortBy[0] !== 'produkt_datumZinsaenderung' &&
      sortBy[0] !== 'produkt' &&
      sortDesc.includes(false)
    ) {
      return items.sort((a, b) => {
        const first = +a[sortBy[0]] || 0;
        const second = +b[sortBy[0]] || 0;
        return first - second;
      });
    }
    return items;
  }
  numberFormat(num) {
    return modifyNumber(num);
  }
  dataInterestCredit(data) {
    if (Number(this.reportType) === 2) {
      return mappedInterestCredit(data, INTEREST_CREDIT_FESTGELD);
    }
    return mappedInterestCredit(data);
  }
  dataInterestCalculation(data) {
    return mappedInterestCalculation(data);
  }
  dataItem(item) {
    if (item && item !== '-') return `${modifyNumber(item)} %`;
    if (!item || item === '-') return '-';
  }
  getSlotName(field) {
    return `item.${field}`;
  }
  productValue(value) {
    if (
      Number(this.reportType) === 1 &&
      +(this.config as any)?.banken_kundenkreis === 1
    ) {
      if (!value) {
        return 'Kein Neukundenangebot';
      } else {
        return value;
      }
    }
    if (
      Number(this.reportType) === 1 &&
      (this.config as any)?.banken_kundenkreis != 1
    ) {
      return value || '-';
    }
    if (
      Number(this.reportType) === 1 &&
      !this.config?.hasOwnProperty('banken_kundenkreis')
    ) {
      return value || '-';
    }
    if (Number(this.reportType) === 2) {
      return value || '-';
    }
  }
  validateDifference(val) {
    return !(
      +val === 0 ||
      val === '0.00' ||
      val === '-' ||
      !val ||
      Number.isNaN(+val)
    );
  }
  setPageBreak() {
    let breakAfter = 8;
    (
      this.$refs.table as any
    ).$el?.childNodes[0]?.childNodes[0]?.childNodes[2]?.childNodes.forEach(
      (item, index) => {
        if (breakAfter === index) {
          item.classList.add('page-break');
          breakAfter += 10;
        }
      }
    );
  }
  mounted() {
    this.setPageBreak();
  }
}
