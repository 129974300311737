import { INTEREST_CREDIT_TAGESGELD } from '@/utils/constants';
import {
  IFestgeldInterestConfig,
  ITagesgeldInterestConfig,
} from '@/interfaces';

export const mappedInterestCredit = (
  value: 0 | 1 | 2 | 3 | 4 | 5,
  localeObject:
    | ITagesgeldInterestConfig
    | IFestgeldInterestConfig = INTEREST_CREDIT_TAGESGELD
) => localeObject[value] || value;
