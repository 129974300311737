
































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  BACKEND_URL,
  BASE_COLOR,
  COLOR,
  SELECTED_COLOR,
} from '@/utils/constants';
import { numberWithCommas } from '../../../../../wettbewerbsvergleich/src/utils/helpers';
import { mapGetters } from 'vuex';

interface ChildrenData {
  value: String;
}
interface DataHeader {
  value: any;
  children: Array<ChildrenData>;
}
interface TableData {
  [Symbol.iterator]();
  sort(arg0: (a: any, b: any) => number);
  map(arg0: (h: any) => any);
  some(arg0: (a: any) => boolean);
  rows: Array<Object>;
  headers: Array<DataHeader>;
}

@Component({
  components: {},
  computed: {
    ...mapGetters({
      globalLoading: 'reports/getDisabled',
    }),
  },
})
export default class FMHAdvancedTable extends Vue {
  @Prop({ default: () => [] }) headers!: TableData;
  @Prop({ default: () => [] }) rows!: TableData;
  @Prop() isCompare?: Boolean;
  @Prop() dateMit?: string;
  @Prop() dateVom?: string;
  @Prop() allBanks?: Array<number>;
  @Prop() loading?: Boolean;

  url: String = BACKEND_URL;
  color: String = COLOR;
  baseColor: String = BASE_COLOR;
  selectedColor: String = SELECTED_COLOR;
  currentOrder = 'asc';
  lastOrder = '';
  clickedHeader = null;
  lastClickedHeader = null;
  originalArray: Array<Object> = [];
  currentRows: Array<Object> = [];
  active: Boolean = false;
  globalLoading?: Boolean;

  processTableRows!: Function;
  getHeadersForXLS!: Function;

  @Watch('rows', { immediate: true }) setRows() {
    this.currentRows = [...this.rows];
    this.originalArray = [...this.rows];
    if (this.allBanks?.length) {
      if (!this.isCompare) {
        this.$emit(
          'updateRowsForXLS',
          this.processTableRows(
            this.getHeadersForXLS(this.headers, this.currentRows),
            this.currentRows
          )
        );
      }
    } else {
      this.$emit(
        'updateRowsForXLS',
        this.processTableRows(
          this.getHeadersForXLS(this.headers, this.currentRows),
          this.currentRows
        )
      );
    }
  }
  @Watch('headers', { immediate: true }) updateHeadersForXls() {
    if (this.allBanks?.length) {
      if (!this.isCompare) {
        this.$emit('updateHeadersForXLS', this.headers);
        this.$emit(
          'updateRowsForXLS',
          this.processTableRows(this.headers, this.currentRows)
        );
      }
    } else {
      this.$emit('updateHeadersForXLS', this.headers);
      this.$emit(
        'updateRowsForXLS',
        this.processTableRows(this.headers, this.currentRows)
      );
    }
  }

  get mappedHeaders() {
    if (this.allBanks?.length) {
      if (this.isCompare) {
        return this.updateHeaders();
      } else {
        this.setDefaultSortValues();
        return this.headers;
      }
    } else {
      return this.updateHeaders();
    }
  }

  get mappedRows() {
    return this.processTableRows(this.mappedHeaders, this.currentRows);
  }

  addFontSollzins(header, value) {
    if (
      this.isCompare &&
      header.includes('produkt_effektivzins_old') &&
      !isFinite(+value)
    ) {
      return true;
    }
    if (header.includes('produkt_sollzins') && !isFinite(+value)) {
      return true;
    }
  }

  setClasses(header) {
    return {
      'hide-header': header.hide,
      [header.className]: header.className,
      'children-header': header.isDynamic,
      'children-header-product': !header.isDynamic,
    };
  }

  setDefaultSortValues() {
    if (this.loading || this.globalLoading) {
      this.active = false;
      this.clickedHeader = null;
      this.lastClickedHeader = null;
      this.lastOrder = '';
      this.currentOrder = 'asc';
    }
  }

  updateHeaders() {
    this.setDefaultSortValues();
    if (this.isCompare) {
      return this.headers.map((header) => {
        if (header?.children) {
          const filtered = header.children.filter(
            (item) =>
              !item.value.includes('anbieter_kreditgeber') &&
              !item.value.includes('produkt_sollzins')
          );
          if (filtered.some((o) => o.value.includes('produkt_effektivzins'))) {
            const arr: object[] = [];
            filtered.forEach((item) => {
              arr.push({
                text: 'Effektivzins\n in %',
                value: `${item.value}_old`,
              });
              arr.push(item);
            });
            return {
              ...header,
              children: arr,
            };
          } else {
            return {
              ...header,
              children: filtered,
            };
          }
        }
        return header;
      });
    } else {
      return this.headers.map((header) => {
        if (header?.children) {
          return {
            ...header,
            children: header.children.filter(
              (item) => !item.value.includes('anbieter_kreditgeber')
            ),
          };
        }
        return header;
      });
    }
  }

  validate(header, compare) {
    if (header && compare?.hasOwnProperty(`${header}_difference`)) {
      return !(
        compare[`${header}_difference`] === 0 ||
        compare[`${header}_difference`] === '0' ||
        !compare[`${header}_difference`] ||
        compare[`${header}_difference`] === '-' ||
        Number.isNaN(+compare[`${header}_difference`])
      );
    }
  }

  numAfterPoint(val) {
    let str = String(val);

    if (Number.isFinite(+val) && str.includes('-')) {
      str = `-${Number(str.slice(1, str.length)).toFixed(2)}`;
    }
    if (Number.isFinite(+val) && !str.includes('-')) {
      str = Number(str).toFixed(2);
    }
    return str;
  }

  modifyDifference(header, number) {
    if (header.includes('produkt')) {
      const num = this.numAfterPoint(number);
      return numberWithCommas(num);
    }
    return numberWithCommas(number);
  }

  iconRotateClass(index) {
    let classes = {};
    if (this.clickedHeader === index) {
      classes = {
        active: this.currentOrder,
        desc: this.currentOrder === 'desc',
        asc: this.currentOrder === 'asc' || !this.currentOrder,
      };
      if (this.lastClickedHeader === this.clickedHeader) {
        classes = {
          ...classes,
          rotateUp: this.currentOrder === 'asc',
          rotateDown: this.currentOrder === 'desc',
        };
      }
    }
    if (
      index === this.lastClickedHeader &&
      this.lastClickedHeader !== this.clickedHeader
    ) {
      classes = {
        ...classes,
        fadeAndRotate: this.lastOrder === 'desc',
        fadeOut: this.lastOrder !== 'desc',
      };
    }
    return classes;
  }

  banksData(header, val) {
    if (val === '99999' || !val) {
      return '-';
    } else {
      if (val.includes('ab')) {
        return val;
      } else {
        if (
          (val.includes('Mindestdarlehen') ||
            val.includes('Maximaldarlehen')) &&
          val.includes('Euro')
        ) {
          const position = val.indexOf('=');
          return [
            val.slice(0, position + 1),
            '\n',
            val.slice(position + 1),
          ].join('');
        } else {
          if (header.includes('produkt')) {
            const num = this.numAfterPoint(val);
            return numberWithCommas(num);
          }
          return numberWithCommas(val);
        }
      }
    }
  }

  customSort({ value: headerName }, index) {
    if (index !== this.clickedHeader) {
      this.lastClickedHeader = this.clickedHeader;
      this.lastOrder = this.currentOrder;
      this.clickedHeader = index;
      this.currentOrder = 'asc';
    } else {
      this.lastClickedHeader = index;
      switch (this.currentOrder) {
        case 'asc':
          this.currentOrder = this.lastOrder = 'desc';
          break;
        case 'desc':
          this.currentOrder = this.lastOrder = 'asc';
          break;
        default:
          this.currentOrder = this.lastOrder = 'asc';
      }
    }

    if (this.currentOrder) {
      if (headerName.includes('anbieter_kreditgeber')) {
        this.currentRows.sort((a, b): number => {
          //eslint-disable-next-line
          return this.currentOrder === 'desc' ? a[headerName] && a[headerName].trim().toLowerCase() > b[headerName] && b[headerName].trim().toLowerCase() ? -1 : 1 : a[headerName] && a[headerName].trim().toLowerCase() > b[headerName] && b[headerName].trim().toLowerCase() ? 1 : -1;
        });
      } else {
        this.currentRows.sort((a, b) => {
          if (!Number.isFinite(+a[headerName])) {
            return this.currentOrder === 'desc' ? 1 : -1;
          }
          const first = +a[headerName];
          const second = +b[headerName] || 0;
          return this.currentOrder === 'desc' ? second - first : first - second;
        });
      }
    } else {
      this.currentRows = [...this.originalArray];
    }
  }

  processTableHeaders(headers) {
    const nested = !!headers.some((h) => h.children);
    if (nested) {
      let children: object[] = [];

      const h = headers.map((h) => {
        const copy = { ...h, isDynamic: false };
        if (copy.value.includes('produkt')) {
          Object.assign(copy, { className: 'font' });
        }
        if (
          !copy.value.includes('produkt') &&
          !copy.value.includes('anbieter')
        ) {
          Object.assign(copy, { isDynamic: true });
        }
        if (copy.children && copy.children.length > 0) {
          const last = copy.children.length - 1;
          const modifiedChildren = copy.children?.map((item, index) => {
            let o = {};
            if (!item.value.includes('anbieter')) {
              Object.assign(o, item, { isDynamic: true });
            }
            if (item.value.includes('kreditgeber')) {
              Object.assign(o, item, { isDynamic: true });
            }
            if (index === 0) {
              Object.assign(o, item, {
                className: 'border-child-left border-right-child-small',
              });
            }
            if (last === index) {
              Object.assign(o, item, { className: 'border-child-right' });
            }
            if (index !== 0 && last !== index) {
              Object.assign(o, item, { className: 'border-right-child-small' });
            }
            return o;
          });
          children.push(...modifiedChildren);
          return {
            description: copy?.description || '',
            rowspan: 1,
            colspan: copy.children.length,
            isDynamic: copy.isDynamic,
            text: copy.text,
            className: copy.className,
          };
        }
        return {
          description: copy?.description || '',
          rowspan: 2,
          colspan: 1,
          isDynamic: copy.isDynamic,
          text: copy.text,
          className: copy.className,
        };
      });
      const filtered = children.filter(
        (item) => !(item as any).value.includes('produkt')
      );

      const modifiedHeaders = h.map((header) => {
        if (header.description.includes('Anbieter')) {
          return {
            ...header,
            descriptionColSpan: 1,
          };
        }
        if (header.description.includes('Muster')) {
          return {
            ...header,
            descriptionColSpan: header.colspan,
          };
        }
        return {
          ...header,
          descriptionColSpan: filtered.length,
        };
      });
      return {
        children: children,
        parents: modifiedHeaders,
      };
    }
    return {
      parents: headers,
    };
  }

  replaceAnbieterData(val) {
    if (val) {
      const url = val.split('|')[1];
      const bankName = val.split('|')[2];
      return {
        url,
        bankName,
      };
    }
    return { url: '', bankName: '' };
  }

  updated() {
    const first = (this.$refs.table as any).$el?.childNodes[0]?.childNodes[0]
      ?.childNodes[1]?.children[0].clientHeight;
    const second = (this.$refs.table as any).$el?.childNodes[0]?.childNodes[0]
      ?.childNodes[1]?.children[1].clientHeight;
    let breakAfter = 4;
    (
      this.$refs.table as any
    ).$el?.childNodes[0]?.childNodes[0]?.childNodes[2]?.childNodes.forEach(
      (item, index) => {
        if (breakAfter === index) {
          item.classList.add('page-break');
          breakAfter += 10;
        }
      }
    );
    (
      this.$refs.table as any
    ).$el?.childNodes[0]?.childNodes[0]?.childNodes[1]?.children[1]?.childNodes.forEach(
      (item) => {
        item.style.top = `${first}px`;
      }
    );
    (
      this.$refs.table as any
    ).$el?.childNodes[0]?.childNodes[0]?.childNodes[1]?.children[2]?.childNodes.forEach(
      (item) => {
        item.style.top = `${first + second}px`;
      }
    );
  }

  mounted() {
    this.currentRows = [...this.rows];
    this.originalArray = [...this.rows];
  }
}
